<template>
  <div>
    <b-button
      v-if="!userData.username"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-primary"
      size="sm"
      href="/login"
    >
      <b>Login</b>
    </b-button>
    <b-button
      v-if="!userData.username"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="primary"
      size="sm"
      href="/register"
    >
      Sign Up
    </b-button>
    <b-nav-item-dropdown
      right
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user"
    >
      <template #button-content>
        <div class="d-sm-flex d-none user-nav">
          <p
            v-if="userData.username"
            class="user-name font-weight-bolder mb-0"
          >
            {{ userData.fullName || userData.username }}
          </p>
          <span
            v-if="userData.username"
            class="user-status"
          >{{ userData.role }}</span>
        </div>
        <b-avatar
          v-if="userData.username"
          size="40"
          :src="avatar"
          variant="light-primary"
          badge
          class="badge-minimal"
          badge-variant="success"
        />
      </template>
      <!-- <b-dropdown-item
      :to="{ name: 'pages-profile'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>Channel</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-email' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MailIcon"
        class="mr-50"
      />
      <span>Messages</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-todo' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CheckSquareIcon"
        class="mr-50"
      />
      <span>Achievements</span>
    </b-dropdown-item>
    <b-dropdown-divider />
 -->
      <b-dropdown-item
        :to="{ name: 'pages-account-setting' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="SettingsIcon"
          class="mr-50"
        />
        <span>Settings</span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="'/'+userData.username"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="VideoIcon"
          class="mr-50"
        />
        <span>My Channel</span>
      </b-dropdown-item>
      <b-dropdown-item
        link-class="d-flex align-items-center"
        @click="logout"
      >
        <feather-icon
          size="16"
          icon="LogOutIcon"
          class="mr-50"
        />
        <span>Logout</span>
      </b-dropdown-item></b-nav-item-dropdown>
  </div>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BButton, BAvatar,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BButton,
    BAvatar,
  },
  data() {
    function parseJwt(token) {
      const base64Url = token.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''))
      return JSON.parse(jsonPayload)
    }
    if (localStorage.getItem('accessToken')) {
      const userData = parseJwt(localStorage.getItem('accessToken'))
      userData.role = 'user'
      // eslint-disable-next-line
      userData.avatar = userAvatar
      userData.ability = [{ action: 'manage', subject: 'all' }]
      return {
        userData,
        avatarText,
        userAvatar: '',
      }
    }
    return {
      userData: {},
      avatarText: {},
      userAvatar: '',
    }
  },
  async mounted() {
    const { avatar } = await window.brime.getUser()
    this.userAvatar = avatar
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      window.brime.logout()
      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>
<style scoped>
.btn {
 margin-left: 5px;
}
</style>
