import useJwt from '@core/auth/jwt/useJwt'
import axios from '@axios'

const authConfig = {
  // Endpoints
  loginEndpoint: 'https://api-staging.brimelive.com/internal/auth/login?client_id=605e4d6b3d79e12e5437985b',
  registerEndpoint: 'https://api-staging.brimelive.com/internal/account/register?client_id=605e4d6b3d79e12e5437985b',
  refreshEndpoint: 'https://api-staging.brimelive.com/internal/auth/refresh?client_id=605e4d6b3d79e12e5437985b',
  logoutEndpoint: '/dfgdfgdfgdfg',
  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',
  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}

const { jwt } = useJwt(axios, authConfig)

export default jwt
