export default [
  {
    header: 'Platform',
    class: 'sidebar-follow-text',
  },
  {
    title: 'Platform Stats',
    icon: 'ActivityIcon',
    route: 'stats',
  },
  {
    title: 'Support',
    icon: 'HelpCircleIcon',
    href: 'https://support.brimelive.com/',
  },
]
