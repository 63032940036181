<template>
  <li
    v-if="canViewVerticalNavMenuLink(item)"
    class="nav-item"
    :class="{
      'active': isActive,
      'disabled': item.disabled
    }"
  >
    <b-link
      v-bind="linkProps"
      class="d-flex align-items-center"
    >
      <div :class="(item.channelData ? 'nav-menu-link-channel ' : ' ') + item.class">
        <feather-icon
          v-if="item.icon"
          :icon="item.icon"
        />
        <b-avatar
          v-if="item.channelData"
          :src="item.channelData.icon ? `https://content.brimecdn.com/cdn-cgi/image/width=100,quality=100/brime/user/${item.channelData.owners[0]}/avatar` : 'https://content.brimecdn.com/brime/assets/default-profile-picture.png'"
        />
        <span
          v-if="!item.channelData"
          class="menu-title text-truncate normalMenu"
        >{{ t(item.title) }}</span>
        <span
          v-if="item.channelData"
          class="menu-title text-truncate channelName"
        >{{ t(item.title) }}</span>
        <span
          v-if="item.channelData"
          class="stream-category"
        >
          <br>
          {{ t(item.channelData.category.name) }}
        </span>
        <b-badge
          v-if="item.tag"
          pill
          :variant="item.tagVariant || 'primary'"
          class="mr-1 ml-auto"
        >
          {{ item.tag }}
        </b-badge>
      </div>
    </b-link>
  </li>
</template>

<!-- Unscoped! -->
<style>
  .main-menu ul.navigation-main {
      padding-bottom: 0px !important;
  }
</style>

<style scoped>
.normalMenu{
  vertical-align: middle !important;
}
.channelName{
font-size: .90rem !important;
margin-top: 0.2em !important;
display: block;
}
  .nav-menu-link-channel {
    transform: translateX(-10px);
  }

  .nav-menu-link-channel .stream-category {
  margin-top: 0.1em;
  margin-bottom: 0.2em;
    padding-bottom: 0.2em;
    display: block;
    font-weight: bold;
    font-size: .80rem;
    margin-left: calc(40px + 1em);
    line-height: .5em;
    opacity: 0.99;
    color: #7367f0
  }

  .nav-menu-link-channel .menu-title {
    font-size: .75rem;
    font-weight: bold;
    margin-left: calc(40px + 1em);
  }

  .nav-menu-link-channel .b-avatar {
    width: 40px !important;
    height: 40px !important;
    position: absolute;
  }
</style>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl'
import {
  BLink, BBadge, BAvatar,
} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'

export default {
  components: {
    BLink,
    BBadge,
    BAvatar,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    }
  },

}
</script>
